import CssBaseline from "@mui/material/CssBaseline";
import AppRoutes from "./routes/AppRoutes";
import { AuthProvider } from "./contexts/AuthContext";
import { AttemptProvider } from "./contexts/AttemptContext";
import { LabsProvider } from "./contexts/LabsContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { FetchProvider } from "./contexts/FetchContext";
import Footer from './components/Footer';

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <FetchProvider>
          <CssBaseline />
          <AttemptProvider>
            <LabsProvider>
              <AppRoutes />
            </LabsProvider>
          </AttemptProvider>
        </FetchProvider>
      </AuthProvider>
      <Footer />
    </ThemeProvider>
  );
}

export default App
