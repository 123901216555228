import { createContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { ToastContainer } from "react-toastify";

const ThemeContext = createContext();

const themeOptions = {
  palette: {
    dark: {
      primary: {
        main: '#59912c',
      },
      secondary: {
        main: '#1ebfaf',
      },
    },
    light: {
      primary: {
        main: '#1ebfaf',
      },
      secondary: {
        main: '#59912c',
      },
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
    ].join(','),
  }
};

const ThemeProvider = ({ children }) => {
  const [colorMode, setColorMode] = useState(localStorage.getItem('colorMode') || 'dark');
  const toggleColorMode = () => colorMode === 'light' ? setColorMode('dark') : setColorMode('light');

  useEffect(() => {
    localStorage.setItem('colorMode', colorMode);
  }, [colorMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode,
          ...themeOptions['palette'][colorMode]
        },
        typography: {
          ...themeOptions['typography']
        }
      }),
    [colorMode],
  );

  return (
    <ThemeContext.Provider value={{ colorMode, toggleColorMode }}>
      <MuiThemeProvider theme={theme}>
        <ToastContainer theme={colorMode} />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };