import React, { createContext, useContext } from 'react'
import { AuthContext } from './AuthContext'
import axios from 'axios'

const FetchContext = createContext()
const { Provider } = FetchContext

const FetchProvider = ({ children }) => {
  const authContext = useContext(AuthContext)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_HOST_URL,
  })

  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Token ${authContext.authState.auth_token}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  authAxios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const code = error?.response?.status || 0

      if (code < 400 || code >= 500) {
        console.error(
          '[Response Interceptor] An unexpected error has occurred. Error code: ',
          code
        )
      } else if (code === 401) {
        authContext.logout()
        return
      }

      return Promise.reject(error)
    }
  )

  return (
    <Provider
      value={{
        authFetch: authAxios,
      }}
    >
      {children}
    </Provider>
  )
}

export { FetchContext, FetchProvider }
