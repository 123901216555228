import { createContext, useContext, useState } from 'react'
import { FetchContext } from './FetchContext'

const LabsContext = createContext();
const { Provider } = LabsContext;


const LabsProvider = ({ children }) => {
  const [currentInterval, setCurrentInterval] = useState(undefined);
  const [labUuid, setLabUuid] = useState(null);
  const [vpnConfigUrl, setVpnConfigUrl] = useState(undefined)
  const [playButtonLoading, setPlayButtonLoading] = useState(false);
  const [playButtonDisabled, setPlayButtonDisabled] = useState(false);
  const [stopButtonLoading, setStopButtonLoading] = useState(false);
  const [stopButtonDisabled, setStopButtonDisabled] = useState(true);
  const [vpnButtonDisabled, setVpnButtonDisabled] = useState(true);
  const [ cohort, setCohort ] = useState({})
  const [labInstances, setLabInstances] = useState([])
  const [runningLabUuid, setRunningLabUuid] = useState(0)

  const {authFetch} = useContext(FetchContext);

  const startLoop = (labUuid) => {
    setLabUuid(labUuid);
    setPlayButtonLoading(true);
    setPlayButtonDisabled(true);
  
    const waiter = currentInterval ? currentInterval : setInterval(async () => {
      let res = await getVpnConfigUrl(labUuid);
      console.log(res?.data) 
  
      if (res?.data?.url !== "") {
        setVpnConfigUrl(res.data.url);
        setVpnButtonDisabled(false);
        setPlayButtonLoading(false);
        setPlayButtonDisabled(true);
        setStopButtonDisabled(false);
        clearInterval(waiter);
        setCurrentInterval(undefined);
      }
    }, 5000)
  }
  
  const getVpnConfigUrl = async (labUuid) => {
    console.log('getVpnConfigUrl, ', labUuid)
    return authFetch.get(`/training_platform/cohorts/${cohort.uuid}/lab_instances/${labUuid}/controller/vpn_config/`);
  }

  return (
    <Provider
      value={{
        startLoop,
        vpnConfigUrl,
        labUuid,
        playButtonLoading,
        playButtonDisabled,
        stopButtonLoading,
        stopButtonDisabled,
        vpnButtonDisabled,
        setStopButtonDisabled,
        setStopButtonLoading,
        setPlayButtonLoading,
        setPlayButtonDisabled,
        getVpnConfigUrl,
        setVpnConfigUrl,
        cohort,
        setCohort,
        runningLabUuid,
        setRunningLabUuid,
        labInstances,
        setLabInstances,
        currentInterval,
        setCurrentInterval
      }}
    >
      {children}
    </Provider>
  )
}

export { LabsContext, LabsProvider }
