import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import App from "./App"
import "./index.css"


Sentry.init({
  dsn: "https://2373628ba8ba43e3504c84fe9ac9fa4e@o4506434999549952.ingest.sentry.io/4506435051782144",
  environment: process.env.REACT_APP_ENV,
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/exams.tcmsecurity\.com/, /^https:\/\/dev.tcmsecurity\.com/],
      routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.feedbackIntegration({
      colorScheme: localStorage.getItem("colorMode") || "dark",
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
