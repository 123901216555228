import React, { createContext, useContext, useState } from 'react'
import Countdown from 'react-countdown'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FetchContext } from '../contexts/FetchContext'

const AttemptContext = createContext()
const { Provider } = AttemptContext

const AttemptProvider = ({ children }) => {
  const [attemptsState, setAttemptsState] = useState([])
  const [attemptState, setAttemptState] = useState({})
  const [roeUrl, setRoeUrl] = useState(undefined)
  const [vpnConfigUrl, setVpnConfigUrl] = useState(undefined)
  const [playButtonDisabled, setPlayButtonDisabled] = useState(true)
  const [playButtonLoading, setPlayButtonLoading] = useState(false)
  const [stopButtonDisabled, setStopButtonDisabled] = useState(true)
  const [stopButtonLoading, setStopButtonLoading] = useState(false)
  const [restartButtonDisabled, setRestartButtonDisabled] = useState(true)
  const [restartButtonLoading, setRestartButtonLoading] = useState(false)
  const [vpnButtonDisabled, setVpnButtonDisabled] = useState(true)
  const [roeButtonDisabled, setRoeButtonDisabled] = useState(true)
  const [reportButtonDisabled, setReportButtonDisabled] = useState(true)
  const [action, setAction] = useState(undefined)
  const [report, setreport] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [percentCompleted, setPercentCompleted] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogText, setDialogText] = useState('')
  const [dialogTitle, setDialogTitle] = useState('')
  const [stackStatus, setStackStatus] = useState(undefined)
  const [currentInterval, setCurrentInterval] = useState(undefined)

  const { authFetch } = useContext(FetchContext)
  const navigate = useNavigate()

  const RESULT_CHOICES = {
    I: 'Incomplete',
    C: 'Awaiting Review',
    F: 'Failed',
    P: 'Passed',
  }

  const setAttemptInfo = (attempt) => {
    if (!attempt) return

    if (attempt.end_time !== null && reportNotPastDue(attempt)) {
      setRoeButtonDisabled(false)
      setReportButtonDisabled(false)
    }

    const status = getExamStatus(attempt)
    const updated = { ...attempt, status }
    setAttemptState(updated)
  }

  const getUserAttempts = async () => {
    try {
      const { data } = await authFetch('/exam_platform/attempts/')

      if (data && data.length === 0) return
      const updated = [...data]

      data.forEach((attempt) => {
        const index = data.indexOf(attempt)
        const status = getExamStatus(attempt)
        updated[index].status = status
      })

      setAttemptsState(updated)
    } catch (err) {
      const data = err?.response?.data
      if (!data?.detail) return
      toast.error(data.detail)
    }
  }

  const getStackStatus = async (attemptId) => {
    try {
      let health = await checkExamHealth(attemptId)

      // Case: AttemptState Expired
      if (attemptState.expired) {
        setStopButtonDisabled(true)
        setRestartButtonDisabled(true)
        setVpnButtonDisabled(true)
        setRoeButtonDisabled(true)
        setReportButtonDisabled(true)
      }

      // Case: DOES_NOT_EXIST
      if (health === 'DOES_NOT_EXIST' && !attemptState.expired) {
        setPlayButtonDisabled(false)
      }

      // Case: CREATE_FAILED
      if (health === 'CREATE_FAILED') {
        setRestartButtonDisabled(false)
        toast.error(
          'Create failed! Please try again. Contact support if the problem persists.'
        )
      }

      // Case: DELETE_IN_PROGRESS
      if (health === 'DELETE_IN_PROGRESS') {
        setStopButtonLoading(true)
        destroyLoop(attemptId)
        setVpnConfigUrl(undefined)
      }

      // Case: CREATE_IN_PROGRESS
      if (health === ('CREATE_IN_PROGRESS' || 'UPDATE_IN_PROGRESS')) {
        setPlayButtonLoading(true)
        setStopButtonDisabled(false)
        createLoop(attemptId)
      }

      // Case: CREATE_COMPLETE
      if (health === ('CREATE_COMPLETE' || 'UPDATE_COMPLETE')) {
        setStopButtonDisabled(false)
        setRestartButtonDisabled(false)
        setVpnButtonDisabled(false)
        setRoeButtonDisabled(false)
        setReportButtonDisabled(false)
        getVpnConfigUrl(attemptId)
      }

      setStackStatus(health)
    } catch (err) {
      const { data } = err.response
      toast.error(data.detail)
    }
  }

  const getRoeUrl = async (attemptId) => {
    try {
      if (attemptId) {
        const { data } = await authFetch.get(
          `/exam_platform/attempts/${attemptId}/controller/roe/`
        )
        setRoeUrl(data.url)
      }
    } catch (err) {
      const { data } = err?.response
      if (data) {
        toast.error(data.detail)
      }
    }
  }

  const getVpnConfigUrl = async (attemptId) => {
    try {
      if (attemptId) {
        const { data } = await authFetch.get(
          `/exam_platform/attempts/${attemptId}/controller/vpn_config/`
        )
      setVpnConfigUrl(data.url)
      }
    } catch (err) {
      const { data } = err.response
      toast.error(data.detail)
    }
  }

  const handleStartExam = () => {
    setPlayButtonLoading(true)
    setStopButtonDisabled(false)
    doHandleStartExam()
  }

  const createLoop = async (attemptId) => {
    const waiter = currentInterval
      ? currentInterval
      : setInterval(async () => {
          let health = await checkExamHealth(attemptId)
          if (health === 'CREATE_COMPLETE') {
            toast.success('Your exam is ready!')
            setPlayButtonLoading(false)
            setPlayButtonDisabled(true)
            setStopButtonDisabled(false)
            setRestartButtonLoading(false)
            setRestartButtonDisabled(false)
            setVpnButtonDisabled(false)
            setRoeButtonDisabled(false)
            setReportButtonDisabled(false)
            getVpnConfigUrl(attemptId)

            await getUserAttempts()
            let attempt = undefined

            Object.keys(attemptsState).forEach((a) => {
              if (attemptsState[a].id === Number(attemptState.id)) {
                attempt = attemptsState[a]
              }
            })

            if (attempt) setAttemptState(attempt)

            clearInterval(waiter)
            setCurrentInterval(undefined)
          } else if (health === 'CREATE_FAILED') {
            setPlayButtonLoading(false)
            setPlayButtonDisabled(true)
            setStopButtonDisabled(true)
            setRestartButtonLoading(false)
            setRestartButtonDisabled(false)
            setVpnButtonDisabled(true)
            setRoeButtonDisabled(true)
            setReportButtonDisabled(true)
            clearInterval(waiter)
            setCurrentInterval(undefined)
            toast.error(
              'Create failed! Please try again. Contact support if the problem persists.'
            )
          }
        }, 5000)

    setCurrentInterval(waiter)
  }

  const doHandleStartExam = async () => {
    try {
      if (attemptState.expired === false) {
        await authFetch.post(
          `/exam_platform/attempts/${attemptState.id}/controller/`
        )
        createLoop()
      } else {
        handleQuitAttempt()
      }
    } catch (err) {
      const { status, data } = err.response
      if (status === 404) navigate('/not-found', { replace: true })
      else toast.error(data.detail)
      setPlayButtonDisabled(false)
      setPlayButtonLoading(false)
    }
  }

  const handleDestroyExam = () => {
    setStopButtonLoading(true)
    setPlayButtonLoading(false)
    setRestartButtonLoading(false)
    setRestartButtonDisabled(true)
    setVpnButtonDisabled(true)
    setVpnConfigUrl(undefined)
    doHandleDestroyExam()
  }

  const destroyLoop = async (attemptId) => {
    const waiter = currentInterval
      ? currentInterval
      : setInterval(async () => {
          let health = await checkExamHealth(attemptId)
          if (health === 'DOES_NOT_EXIST') {
            toast.success('Exam environment successfully terminated.')
            setPlayButtonDisabled(false)
            setStopButtonDisabled(true)
            setStopButtonLoading(false)
            clearInterval(waiter)
            setCurrentInterval(undefined)
          }
        }, 5000)

    setCurrentInterval(waiter)
  }

  const doHandleDestroyExam = async () => {
    try {
      await authFetch.delete(
        `/exam_platform/attempts/${attemptState.id}/controller/`
      )
      destroyLoop()
    } catch (err) {
      const { data } = err.response
      toast.error(data.detail)

      setStopButtonDisabled(true)
      setStopButtonLoading(false)
    }
  }

  const handleRestartExam = () => {
    setRestartButtonLoading(true)
    setRestartButtonDisabled(true)
    setVpnButtonDisabled(true)
    setVpnConfigUrl(undefined)
    doHandleRestartExam()
  }

  const doHandleRestartExam = async () => {
    try {
      await authFetch.delete(
        `/exam_platform/attempts/${attemptState.id}/controller`
      )

      const waiter = currentInterval
        ? currentInterval
        : setInterval(async () => {
            // Not immediately destructuring here because this creates a bug when authentication
            // expires and the interval continues to run. Instead, use the optional chain operator
            // to perform logical checks against expected properties.
            const res = await authFetch.post(
              `/exam_platform/attempts/${attemptState.id}/controller/`
            )

            if (res?.status === 201) {
              clearInterval(waiter)
              setCurrentInterval(undefined)
            }
          }, 5000)

      setInterval(waiter)
      createLoop()
    } catch (err) {
      const data = err?.response?.data

      if (!data?.detail) return

      toast.error(data.detail)
      setRestartButtonDisabled(false)
      setRestartButtonLoading(false)
    }
  }

  const handleQuitAttempt = async () => {
    try {
      const { data } = await authFetch.delete(
        `/exam_platform/attempts/${attemptState.id}/controller/give_up_attempt/`
      )
      const attempt = { ...data }
      attempt['expired'] = true
      attempt['exam'] = attemptState['exam']
      setAttemptInfo(attempt)

      setStopButtonLoading(false)
      setStopButtonDisabled(false)
      setReportButtonDisabled(false)
      setVpnButtonDisabled(true)
      setRoeButtonDisabled(true)
    } catch (err) {
      const { data } = err.response
      toast.error(data.detail)

      setPlayButtonDisabled(true)
      setStopButtonDisabled(true)
      setRestartButtonDisabled(true)
      setVpnButtonDisabled(true)
      setRoeButtonDisabled(true)
    }
  }

  const handleQuitNoReport = async () => {
    try {
      const { data } = await authFetch.delete(
        `/exam_platform/attempts/${attemptState.id}/controller/give_up_report/`
      )
      const attempt = { ...data }
      attempt['exam'] = attemptState['exam']
      setAttemptInfo(attempt)
      setReportButtonDisabled(true)
    } catch (err) {
      const { data } = err.response
      toast.error(data.detail)
    }
  }

  const checkExamHealth = async (attemptId) => {
    const id = attemptId ? attemptId : attemptState.id

    const res = await authFetch.get(`/exam_platform/attempts/${id}/controller/`)

    try {
      return res.headers['x-stack-status']
    } catch (err) {
      console.error(err)
    }
  }

  const handleCloseDialog = (e) => {
    e.preventDefault()

    if (e.type === 'keydown' && e.key === 'Escape') {
      setAction(undefined)
      setOpenDialog(false)
      return
    } else if (e?.type === 'keydown') return

    const { outerText } = e.target

    // For some reason, in Safari / on macOS,
    // the outerText is read as "AGREE/n" so this block
    // never triggers. Calling the trim() method removes the whitespace
    // if it exists and does nothing if it doesn't...
    // TLDR: This is to make this work on macOS.
    if (outerText.trim() === 'AGREE') {
      if (action === 'QUIT') {
        handleDestroyExam()
        handleQuitAttempt()
      } else if (action === 'QUIT_NO_REPORT') {
        handleQuitNoReport()
      } else if (action === 'CREATE') {
        handleStartExam()
      } else if (action === 'DESTROY') {
        handleDestroyExam()
      } else if (action === 'RESTART') {
        handleRestartExam()
      } else {
        onDrop(report)
      }
    }

    setAction(undefined)
    setOpenDialog(false)
  }

  const handleOpenDialog = (e) => {
    const { outerText } = e?.target ? e.target : e[0]

    if (e?.target?.id === 'quit') {
      setDialogText(
        "By clicking 'Agree', you acknowledge the following: This is a destructive action! This will terminate your exam attempt. You will have the duration specified in the rules of engagement to submit your report. This action cannot be undone!"
      )
      setDialogTitle('Terminate exam?')
      setAction('QUIT')
      setOpenDialog(true)
    } else if (e?.target?.id === 'quit_noreport') {
      setDialogText(
        "By clicking 'Agree', you acknowledge the following: This is a destructive action! This will terminate your reporting period. You will not be able to submit your report. This action cannot be undone!"
      )
      setDialogTitle('Quit?')
      setAction('QUIT_NO_REPORT')
      setOpenDialog(true)
    } else if ((outerText && outerText.trim()) === 'play_arrow') {
      if (attemptState.start_time === null) {
        setDialogText(
          "By clicking 'Agree', you acknowledge the following: This will start your exam. Once the environment is created, your exam countdown will begin and an attempt will be counted. This action cannot be undone!"
        )
        setDialogTitle('Begin Exam?')
      } else {
        setDialogText(
          "By clicking 'Agree', you acknowledge the following: This will start create a new environment. Any changes you have made in a previous environment will be lost and you will be required to download a new VPN configuration file."
        )
        setDialogTitle('Start environment?')
      }

      setAction('CREATE')
      setOpenDialog(true)
    } else if ((outerText && outerText.trim()) === 'stop') {
      setDialogText(
        "By clicking 'Agree', you acknowledge the following: This is a destructive action! If you start a new exam environment, any changes you have made will be lost and you will be required to download a new VPN configuration file. This action cannot be undone!"
      )
      setDialogTitle('Stop Exam Environment?')
      setAction('DESTROY')
      setOpenDialog(true)
    } else if ((outerText && outerText.trim()) === 'restart_alt') {
      setDialogText(
        "By clicking 'Agree', you acknowledge the following: This is a destructive action! If you restart your exam environment, any changes you have made will be lost and you will be required to download a new VPN configuration file. This action cannot be undone!"
      )
      setDialogTitle('Restart Exam Environment?')
      setAction('RESTART')
      setOpenDialog(true)
    } else {
      setreport(e)
      setDialogText(
        "By clicking 'Agree', you acknowledge the following: By submitting your report, you are ending this exam attempt. The report that you submit will be used to assess your performance. This action cannot be undone!"
      )
      setDialogTitle('Submit Report')
      setOpenDialog(true)
    }
  }

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles?.length > 0 && acceptedFiles[0].type === 'application/pdf') {
      const file = acceptedFiles[0]
      const fileName = `${attemptState.exam.acronym}-${attemptState.student}.pdf`

      const formData = new FormData()
      formData.append('url', file, fileName)
      formData.append('attempt', Number(attemptState.id))
      await authFetch.post(
        `/exam_platform/attempts/${attemptState.id}/reports/`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent
            setPercentCompleted(Math.round((loaded / total) * 100))

            if (percentCompleted < 100) setUploading(true)
          },
        }
      )
      setUploading(false)
      handleDestroyExam()

      await getUserAttempts()
      let attempt = undefined

      Object.keys(attemptsState).forEach((a) => {
        if (attemptsState[a].id === Number(attemptState.id)) {
          attempt = attemptsState[a]
        }
      })

      if (attempt) setAttemptState(attempt)
    } else toast.error('Please save your report as a .PDF and try again.')
  }

  const getExamStatus = (attempt) => {
    const {
      expired,
      start_time,
      end_time,
      report_deadline,
      report,
      briefing_result,
      result,
    } = attempt

    if (!expired && !start_time) return 'Not started'
    else if (!expired && start_time) {
      const timeRemaining = new Date(end_time) - Date.now()
      return <Countdown date={Date.now() + timeRemaining} />
    } else if (expired && reportNotPastDue(attempt) && !report) {
      const timeRemaining = new Date(report_deadline) - Date.now()
      return <Countdown date={Date.now() + timeRemaining} />
    } else if (report && report.result === 'C')
      return RESULT_CHOICES[report.result]
    else if (report?.result === 'P' && briefing_result === 'I') {
      return 'Report Accepted -- Awaiting Debrief'
    } else return RESULT_CHOICES[result]
  }

  const reportNotPastDue = (attempt) => {
    const report_deadline = !attempt
      ? attemptState.report_deadline
      : attempt.report_deadline
    return Boolean(new Date(report_deadline) - Date.now() >= 0)
  }

  return (
    <Provider
      value={{
        RESULT_CHOICES,
        setAttemptState: (attempt) => setAttemptInfo(attempt),
        getUserAttempts,
        attemptsState,
        attemptState,
        currentInterval,
        setCurrentInterval,
        getStackInfo: (attemptId) => getStackStatus(attemptId),
        getRoeUrl,
        roeUrl,
        getVpnConfigUrl,
        vpnConfigUrl,
        playButtonDisabled,
        playButtonLoading,
        stopButtonDisabled,
        stopButtonLoading,
        restartButtonDisabled,
        restartButtonLoading,
        vpnButtonDisabled,
        setVpnButtonDisabled,
        roeButtonDisabled,
        setRoeButtonDisabled,
        reportButtonDisabled,
        setReportButtonDisabled,
        action,
        report,
        uploading,
        percentCompleted,
        openDialog,
        dialogText,
        dialogTitle,
        handleOpenDialog,
        handleCloseDialog,
        getExamStatus,
        setStackStatus,
        handleStartExam,
        handleDestroyExam,
        handleRestartExam,
        reportNotPastDue,
      }}
    >
      {children}
    </Provider>
  )
}

export { AttemptContext, AttemptProvider }
