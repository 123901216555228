import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Card from './common/Card'

function LabsCards({ cohort, type, labInstances }) {
  const navigate = useNavigate();

  console.log(labInstances)

  return (
    <Grid container spacing={3} display='flex' justifyContent='center' sx={{ pt: 3 }}>
      {cohort.length === 0 ? (
        <Typography
          variant='body1'
        >
          {(type === 'current' || !type) && 'You have no valid exam vouchers at this time.'}
          {type === 'past' && 'You have no past exams at this time.'}
        </Typography>
      ) :
        labInstances.length > 0 && (
          labInstances.map(c => {
            return (
              <Grow
                key={c.uuid}
                in={labInstances.length > 0}
                style={{ transformOrigin: '0 0 0' }}
              >
                <Grid item sm={12} md={6} lg={6}>
                  <Card
                    title={c?.lab.name}
                    subheader={c?.lab_is_active ? 'Running' : ''}
                    image={c?.lab?.image?.url || null}
                    text={c?.exam?.description || null}
                    actions={renderActions(navigate, c?.uuid)}
                  />
                </Grid>
              </Grow>
            )
          })
        )}
    </Grid>
  );
}

export default LabsCards;

const renderActions = (navigate, uuid) => {

  const handleClick = () => { navigate(`/training/lab_instance/${uuid}`); }

  return (
    <Button
      variant='contained'
      onClick={handleClick}
    >
      <Icon sx={{ mr: 1 }}>info</Icon> Details
    </Button>
  )
}