import React, { createContext, useState } from 'react';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const auth_token = localStorage.getItem('auth_token');
  const userInfo = localStorage.getItem('userInfo');
  const ephemeral_token = null;

  const [authState, setAuthState] = useState({
    auth_token,
    ephemeral_token,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  })

  const setAuthInfo = ({ auth_token, ephemeral_token, userInfo }) => {
    setAuthState({
      auth_token,
      ephemeral_token,
      userInfo,
    });

    localStorage.setItem('auth_token', auth_token ? auth_token : '')
    localStorage.setItem('userInfo', userInfo ? JSON.stringify(userInfo) : '')
  }

  const logout = () => {
    const authKeys = ['auth_token', 'userInfo']

    authKeys.forEach(k => localStorage.removeItem(k))

    setAuthState({
      auth_token: null,
      userInfo: {},
    })
  }

  const isAuthenticated = () => Boolean(localStorage.getItem('auth_token'));
  const isBanned = () => Boolean(authState?.userInfo?.is_banned);

  return (
    <Provider
      value={{
        authState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        isAuthenticated,
        isBanned,
        logout
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };