import React from 'react';
import { Card as MuiCard } from '@mui/material';
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

function Card({ title, subheader, image, text, actions }) {
  return (
    <MuiCard sx={{ maxWidth: 800 }}>
      {title && <CardHeader
        title={title}
        subheader={subheader}
      />}
      {image && <CardMedia
        component='img'
        height='225'
        src={image}
      />}
      {text && <CardContent>
        <Typography
          variant='body1'
          component='p'
        >
          {text}
        </Typography>
      </CardContent>}
      {actions && <CardActions sx={{ justifyContent: 'flex-end', pr: 2, pb: 2 }}>
        {actions}
      </CardActions>}
    </MuiCard>
  );
}

export default Card;